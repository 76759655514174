<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Site List" page-icon="mdi-sitemap">
    <g-list-page
        :columns="columns"
        :headers="headers"
        sort="site_id"
        :descending="true"
        stateendpoint="site.sites"
        :actions="actions"
        @loadData="loadData"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @switchHandler="switchHandler"
        @wpAccessHandler="wpAccessHandler"
        @restoreItem="restoreItem"
        @switchBrokenHandler="switchBrokenHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Site"
                :toolbar="true"
                @onButtonClick="openPopUp"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <component
        is="SitePopup"
        ref="component"
        :basic-data="basicData"
        :item="editedItem"
        :show="showPopup"
        @closePopupHandler="closePopupHandler"
        @onPopupShow="openPopUp"
        @saveItemHandler="saveItemHandler"
    />
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import ListMixins from "../components/mixins/list/List";
import SitePopup from "../components/popups/SitePopup";
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import AddButton from "../components/buttons/AddButton";
import GPSearch from "../components/GPSearch";
import PageOverlay from "../components/PageOverlay";
import ConfirmPopup from "../components/popups/ConfirmPopup";

export default {
  name: 'Sites',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Site Lists | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    ConfirmPopup,
    PageOverlay,
    GPSearch,
    AddButton,
    GListPage,
    GCard,
    SitePopup
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Site ID',
          value: 'site_id'
        },
        {
          text: 'Site URL',
          value: 'site_url'
        },
        /*{
          text: 'Google News',
          value: 'google_news'
        },
        {
          text: 'Other',
          value: 'casino'
        },
        {
          text: 'Do Follow',
          value: 'follow'
        },*/
        {
          text: 'General Admin Price',
          align: 'right',
          value: 'adminPrice'
        },
        {
          text: 'Others Admin Price',
          align: 'right',
          value: 'adminOthersPrice'
        },
        {
          text: 'Price',
          align: 'right',
          value: 'price'
        },
        {
          text: 'Reseller Price',
          align: 'right',
          value: 'reseller_price'
        },
        {
          text: 'Ahrefs',
          value: 'ahrefs'
        },
        {
          text: 'Is WP Access',
          value: 'isWPAccess',
          align: "center"
        },
        {
          text: 'Status',
          value: 'is_active',
          align: "center"
        },
        {
          text: 'Is check broken link?',
          value: 'isCheckBrokenLink',
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      actions: {
        load: 'site/load',
        store: 'site/store',
        update: 'site/update',
        remove: 'site/remove',
        destroy: 'site/destroy'
      },
      basicData: {
        pa: 0,
        da: 0,
        dr: 0,
        ur: 0,
        traffic: 0,
        backLinks: 0,
        adminPrice: 0,
        adminOthersPrice: 0,
        price: 0,
        reseller_price: 0,
        linkPrice: 0,
        adultPrice: 0,
        follow: true,
        is_active: true,
        isCheckBrokenLink: true,
        google_news: true,
        casino: true,
        nichecategories: []
      },
      columns: [
        {
          name: "adminPrice",
          callback: "getMoneySign"
        },
        {
          name: "adminOthersPrice",
          callback: "getMoneySign"
        },
        {
          name: "price",
          callback: "getMoneySign"
        },
        {
          name: "reseller_price",
          callback: "getMoneySign"
        },
        {
          name: "is_active",
          callback: ""
        },
      ],
    }
  },
  methods: {
    async switchBrokenHandler({item, eventStatus}) {
      await this.handleItem(this.actions.update, {...item, isCheckBrokenLink: eventStatus});
    },
  }
}
</script>
