<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid || isSiteValid"
      :width="56"
      :overlay="overlay"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveSiteHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container class="mt-5">
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-0"
          >
            <v-text-field
                v-model="currentItem.site_url"
                :rules="requiredFieldRule"
                label="Site Url"
                outlined
                append-icon="mdi-web"
                required
                dense
                @blur="handleSearch(currentItem.site_url)"
                :messages = siteError
                class="site-field"
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-0"
          >
            <v-text-field
                v-model.number="currentItem.adminPrice"
                :min="1"
                :rules="numberRule"
                class="right-align-text"
                label="General Admin Price"
                onclick="this.select()"
                prepend-inner-icon="attach_money"
                required
                type="number"
                outlined
                dense
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-0"
          >
            <v-text-field
                v-model.number="currentItem.adminOthersPrice"
                :min="1"
                :rules="numberRule"
                class="right-align-text"
                label="Others Admin Price"
                onclick="this.select()"
                prepend-inner-icon="attach_money"
                required
                type="number"
                outlined
                dense
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-text-field
                v-model.number="currentItem.price"
                :min="1"
                :rules="numberRule"
                class="right-align-text"
                label="Price"
                onclick="this.select()"
                prepend-inner-icon="attach_money"
                required
                type="number"
                outlined
                dense
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-text-field
                v-model.number="currentItem.reseller_price"
                :min="1"
                :rules="numberRule"
                class="right-align-text"
                label="Reseller Price"
                onclick="this.select()"
                prepend-inner-icon="attach_money"
                required
                type="number"
                outlined
                dense
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-text-field
                v-model.number="currentItem.linkPrice"
                :min="1"
                :rules="numberRule"
                class="right-align-text"
                label="Normal Post / Link Price"
                onclick="this.select()"
                prepend-inner-icon="attach_money"
                required
                type="number"
                outlined
                dense
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-text-field
                v-model.number="currentItem.adultPrice"
                :min="1"
                :rules="numberRule"
                class="right-align-text"
                label="Other Post Link Price"
                onclick="this.select()"
                prepend-inner-icon="attach_money"
                required
                type="number"
                outlined
                dense
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
              class="pb-1 pt-0"
          >
            <g-p-auto-complete
                :dense="true"
                :outlined="true"
                :search-value="currentItem.nichecategories"
                :status=true
                :validation="false"
                search-label="Select Category"
                apiPath="niche-categories"
                class="discount-field mt-2 mb-1"
                :multiple="true"
                @clearSearchResult="onSelectionsNicheCategory"
                @selectSearchResult="onSelectionsNicheCategory"
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
              class="pb-1 pt-0"
          >
            <g-p-auto-complete
                :dense="true"
                :outlined="true"
                :search-value="currentItem.site_owner"
                :status=true
                :validation="false"
                search-label="Site Owner"
                apiPath="site-owners"
                class="discount-field mt-2 mb-1"
                @clearSearchResult="handleClearSiteOwnerSearchResult"
                @selectSearchResult="onSelectionsSiteOwner"
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
              class="pb-1 pt-0"
          >
            <v-textarea
                v-model.number="currentItem.description"
                label="Description"
                outlined
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Google News</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.google_news"
                    color="primary"
                    dense
                    inset
                    prefix="Status"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Other</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.casino"
                    color="primary"
                    dense
                    inset
                    prefix="Status"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Do Follow</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.follow"
                    color="primary"
                    dense
                    inset
                    prefix="Status"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Status</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.is_active"
                    color="primary"
                    dense
                    inset
                    prefix="Status"
                />
              </v-col>
            </v-row>

          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Is check broken link?</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.isCheckBrokenLink"
                    color="primary"
                    dense
                    inset
                    prefix="Status"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Is WP Access?</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.isWPAccess"
                    color="primary"
                    dense
                    inset
                    prefix="Status"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import Api from "../../services/Api";
import GPAutoComplete from "../GPAutoComplete";

export default {
  name: 'SitePopup',
  components: {GPAutoComplete, BasePopup},
  mixins: [PopupMixin],
  data() {
    return {
      term$: new Subject(),
      isSiteValid: false,
      siteError: '',
      overlay: false
    }
  },
  mounted() {
    this
        .term$
        .pipe(
            //filter(term => term && term.length > 2),
            debounceTime(500),
            distinctUntilChanged()
        )
        .subscribe(term => this.apiSearch(term));
  },
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Site'
          : 'Add Site'
    },
  },
  methods: {
    handleSearch(term) {
      this.term$.next(term);
    },
    apiSearch(term) {
      if(!term) {
        this.isSiteValid = true;
        this.siteError = "";
        return;
      }
      let id = null;
      if(this.isEditMode) {
        id = this.currentItem.id
      }
      //const path = `/site/find-by-site-url/${term || ''}/${id}`;
      const path = `/site/find-by-site-url?id=${id}&siteUrl=${term}`;
      this.siteError = '';
      Api.getRequest({endpoint: path})
          .then(({data: {data}}) => {
            this.isSiteValid = data.isSiteValid;
            if(this.isSiteValid) {
              this.siteError = 'This website is already exists!'
            }
          })
          .catch((err) => {
            console.log(err)
          })
    },
    aHrefsHandler(target) {
      //this.overlay = true;
      if(!this.isSiteValid) {
        /*Api.postRequest({endpoint: '/a-hrefs-domain-checker', data: {siteUrl: target}})
            .then((response)=> {
              const {drInfo, urInfo, backLinks, traffic} = response.data;

              const _drInfo = JSON.parse(drInfo);
              const _urInfo = JSON.parse(urInfo);
              const _backLinks = JSON.parse(backLinks);
              const _traffic = JSON.parse(traffic);

              this.currentItem.dr = _drInfo && _drInfo.domain? _drInfo.domain.domain_rating : 0
              this.currentItem.ur = _urInfo && _urInfo.pages? _urInfo.pages[0].ahrefs_rank : 0
              this.currentItem.backLinks = _backLinks && _backLinks.stats? _backLinks.stats.backlinks : 0
              this.currentItem.traffic = _traffic && _traffic.metrics? parseFloat(_traffic.metrics.traffic).toFixed(2) : 0

              this.overlay = false;
            })
            .catch((err) => {
              this.overlay = false;
            })*/
      }

    },
    handleClearSiteOwnerSearchResult(item) {
      this.currentItem.siteOwner = null;
      this.currentItem.siteOwnerId = '';
    },
    onSelectionsSiteOwner(item) {
      this.currentItem.site_owner = {...item};
      this.currentItem.siteOwnerId = item.id;
    },
    onSelectionsNicheCategory(selections){
      this.currentItem.nichecategories = selections;
    },
    saveSiteHandler() {
      if (!this.isFormValid) {
        return;
      }
      this.currentItem.nichecategories = this.currentItem.nichecategories.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      })
      this.$emit('saveItemHandler', {...this.currentItem});
      this.closePopupHandler();
    },
  }
}
</script>

<style lang="scss">
.site-field .v-messages__message{
  color: red;
}
</style>